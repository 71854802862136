import ChevronButton from 'components/ChevronButton'
import useEmblaCarousel from 'embla-carousel-react'
import cx from 'classnames'
import { useState, useCallback, useEffect } from 'react'
import { wrap } from 'martha'
import StarRating from 'components/StarRating'
import Link from 'components/Link'
import CarouselPagination from 'components/CarouselPagination'

const BackgroundColors = ['bg-alice-blue', 'bg-pale-orange', 'bg-blush-pink']

export default function CuratedReviewsCarousel({ module, settings }) {
  const [ref, embla] = useEmblaCarousel({
    loop: true,
    align: 'center',
  })

  const isCarouselEnabled = module?.reviews.length > 1

  const [index, setIndex] = useState(0)
  const scrollPrev = useCallback(() => embla?.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla?.scrollNext(), [embla])
  const scrollTo = useCallback(embla?.scrollTo, [embla])

  const onSelect = useCallback(() => {
    if (!embla) return
    setIndex(embla.selectedScrollSnap())
  }, [embla])

  useEffect(() => {
    if (!embla) return
    onSelect()
    embla.on('select', onSelect)
  }, [embla, onSelect])

  return (
    <div
      className={cx('relative', {
        'mt-50 l:mt-120': settings.hasMarginTop,
        'mb-50 l:mb-120': settings.hasMarginBottom,
      })}
    >
      <div className="px-44">
        <h3 className="text-24 s:text-26 l:text-32 mb-20 xl:mb-30 font-medium leading-130 text-center">
          {module.headline}
        </h3>
      </div>
      <div className="relative">
        <div className="overflow-hidden px-15 s:px-25" ref={isCarouselEnabled ? ref : null}>
          <div
            className={cx('flex items-stretch -mx-8 s:-mx-15 l:-mx-20', {
              'justify-center': !isCarouselEnabled,
            })}
          >
            {module?.reviews.length
              ? module.reviews.map((review, i) => {
                  const bgColor = BackgroundColors[wrap(i, BackgroundColors.length)]
                  return (
                    <div
                      key={review._key}
                      className="relative min-w-full s:min-w-70p px-8 s:px-15 xl:px-20"
                    >
                      <div
                        className={cx(
                          'overflow-hidden rounded py-50 px-30 text-center h-full',
                          bgColor,
                        )}
                      >
                        <div
                          className={cx('space-y-25 xl:space-y-30 transition', {
                            'opacity-0': index !== i,
                          })}
                        >
                          <div className="flex justify-center">
                            <StarRating 
                              score={review.rating} 
                              // fill={'navy-blue'} 
                              size={19} 
                            />
                          </div>
                          <p className="text-16 m:text-20 xl:text-24 max-w-715 mx-auto">
                            “{review.quote}”
                          </p>
                          <p className="text-14 m:text-15 xl:text-16 font-medium">
                            — {review.name} on{' '}
                            <Link className="underline" link={review.link}>
                              {review.productTitle}
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                })
              : null}
          </div>
        </div>
        {isCarouselEnabled ? (
          <div
            className={cx(
              'h-full w-200 absolute top-0 left-0 z-2 bg-gradient-to-l from-transparent-white to-white transition pointer-events-none hidden s:block',
            )}
          >
            <div className="absolute left-8 top-1/2 transform -translate-y-1/2 pointer-events-auto">
              <ChevronButton direction="left" onClick={scrollPrev} label="Prev slide" />
            </div>
          </div>
        ) : null}
        {isCarouselEnabled ? (
          <div
            className={cx(
              'h-full w-200 absolute top-0 right-0 z-2 bg-gradient-to-r from-transparent-white to-white transition pointer-events-none hidden s:block',
            )}
          >
            <div className="absolute right-8 top-1/2 transform -translate-y-1/2 pointer-events-auto">
              <ChevronButton direction="right" onClick={scrollNext} label="Next slide" />
            </div>
          </div>
        ) : null}
      </div>
      {isCarouselEnabled ? (
        <div className="mt-15 s:mt-25 xl:mt-35">
          <CarouselPagination count={module?.reviews?.length} scrollTo={scrollTo} index={index} />
        </div>
      ) : null}
    </div>
  )
}
